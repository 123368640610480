<body>
  <div class="vacation-overlay">
    <div class="vacation-inner">
      <h1>Vi har lukket shoppen <i class="fal fa-smile"></i></h1>
      <p>Shoppen er lukket pr. 18. december 2024</p>
    </div>
  </div>
  <app-nav-menu></app-nav-menu>
  <main class="main">
    <div [@routeAnimation]="getAnimationData(routerOutlet)">
      <router-outlet #routerOutlet="outlet"></router-outlet>
    </div>
    <app-footer></app-footer>
  </main>
</body>
